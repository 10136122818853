<template>
  <div class="home">
    <div class="tips">正在跳转</div>
    <!-- <van-form @submit="onSubmit">
      <van-field
        v-model="formData.userPhone"
        name="登录手机"
        label="登录手机"
        placeholder="请输入"
       
      />
      <van-field
        v-model="formData.password"
        type="password"
        name="初始密码"
        label="初始密码"
        placeholder="请输入"
        :rules="codeRules"
      />
      <div style="margin: 16px">
        <van-button block type="info" native-type="submit"
          >下一步</van-button
        >
      </div>
    </van-form> -->
  </div>
</template>


<script>
import { stafflogin } from "@/api/auth/auth";
import { wxlogin } from "@/api/auth/auth";
import { setToken } from "@/utils/auth";
import { Notify, Button, Field, Form } from "vant";
export default {
  name: "home",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
    [Notify.Component.name]: Notify.Component,
  },
  mounted() {
    this.getskip();
  },
  data() {
    return {
      formData: {
        userPhone: "",
        password: "",
      },
      // 校验手机号码
      telRules: [
        {
          required: true,
          message: "登录手机不能为空",
          trigger: "onBlur",
        },
        {
          // 自定义校验规则
          validator: (value) => {
            return /^1[3456789]\d{9}$/.test(value);
          },
          message: "请输入正确格式的手机号码",
          trigger: "onBlur",
        },
      ],
      // 验证初始密码
      codeRules: [
        {
          required: true,
          message: "初始密码不能为空",
          trigger: "onBlur",
        },
      ],
    };
  },
  methods: {
    getskip() {
      let ref = window.location.href;
      if (ref.indexOf("?p=") != -1) {
        let ref1 = ref.substring(ref.indexOf("?p=") + 3, ref.indexOf("&code"));
        if (ref1.indexOf("huodong") != -1) {
          let ref2 = ref1.split("/");
          let code = ref.substring(ref.indexOf("&code")+6,ref.indexOf("&state"))
          let userid = "";
          console.log(ref2,userid,code)
          wxlogin({wxCode:code}).then((res)=>{
            userid = res.data;
            let href1 = "https://zjh5.gsemanager.cn/?id="+ref2[1]+"#/activity-home"+"?p="+userid
            window.location.replace(href1);
          });
        } else if (ref1.indexOf("chongzhi") != -1) {
          this.$router.push({ name: "personal-info" });
        } else if(ref1.indexOf("xuexi") != -1){
          let code = ref.substring(ref.indexOf("&code")+6,ref.indexOf("&state"))
          let userid = "";
          wxlogin({wxCode:code}).then((res)=>{
            userid = res.data;
            console.log(res);
            let href1 = "https://zjh5.gsemanager.cn/#/study-file"+"?p="+userid
            window.location.replace(href1);
          });
        }
      } else {
        console.log("111", window.location.href);
      }
    },
    // 确认并绑定改微信
    onSubmit() {
      if (this.formData.userPhone && this.formData.password) {
        let obj = {
          LoginName: this.formData.userPhone,
          Password: this.formData.password,
        };
        stafflogin({ ...obj }).then((res) => {
          if (res.code == 200) {
            let token = res.remark;
            setToken(token);
            let newObj = {};
            newObj.name = res.data.name;
            encodeURI(newObj);
            this.$router.push({
              name: "personal-info",
              params: { newObj: newObj },
            });
            // this.$router.push({ name: "form-complete"});
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100vh;
  background: #eef1f6;
  .tips {
    color: #333;
    padding: 20px 40px;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
  }
}
</style>
