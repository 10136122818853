import request from '@/utils/request'

export function stafflogin(data) {
    return request({
        url: '/auth/Auth/stafflogin',
        method: 'get',
        params:data
    })
}
export function wxlogin(data) {
    return request({
        url: '/auth/Auth/wxlogin',
        method: 'get',
        params:data
    })
}
